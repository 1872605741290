import { LogoutOutlined } from "@ant-design/icons"
import { Menu, MenuProps, Modal, Row, Space } from "antd"
import Paragraph from "antd/lib/typography/Paragraph"
import { FC, Key, ReactNode, useEffect, useState } from "react"
import { Link, NavLink, RouteObject, useLocation } from "react-router-dom"

import { ReactComponent as Contacts } from "src/icons/contacts.svg"
import { ReactComponent as FileList } from "src/icons/fi-list.svg"
import { ReactComponent as Home } from "src/icons/home.svg"
import { ReactComponent as Invoices } from "src/icons/invoices.svg"
import { ReactComponent as Notepad } from "src/icons/notepad.svg"
import { ReactComponent as Quotes } from "src/icons/quotes.svg"
import { ReactComponent as Sales } from "src/icons/sales.svg"
import { ReactComponent as Settings } from "src/icons/settings.svg"
import { useApp } from "../../app"
import { useBreakpoints } from "../../hooks/useBreakpoints"
import { ReactComponent as LogoSvg } from "../../icons/logo.svg"
import "./SideBarMenu.less"

type MenuItem = Required<MenuProps>["items"][number] & { route: string }

const SideBarMenu: FC<{ onSelect?: () => void }> = ({ onSelect }) => {
  const [selectedKey, setSelectedKey] = useState("")
  const location = useLocation()
  const { isMobile } = useBreakpoints()

  const handleSignOut = () => {
    localStorage.removeItem("jwt")
    window.location.reload()
  }

  const handleConfirmSignOut = () => {
    Modal.confirm({
      title: "Are you sure you want to log out?",
      onOk: handleSignOut,
    })
  }

  const {
    user: { role },
  } = useApp()

  function getItem(
    label: ReactNode,
    key: Key,
    route: string,
    icon?: ReactNode,
    children?: MenuItem[]
  ): MenuItem | null {
    const onsiteMenu: string[] = ["dashboard", "profile"]
    const yardMenu: string[] = ["dashboard", "profile", "jobs", "contacts", "logout"]
    const adminMenu: string[] = [
      "dashboard",
      "profile",
      "jobs",
      "quotes",
      "invoices",
      "contacts",
      "sales",
      "forms",
      "logout",
    ]

    if ((role as RoleType) === "YARD") {
      if (!yardMenu.includes(route)) {
        return null
      }
    }

    if ((role as RoleType) === "ONSITE") {
      if (!onsiteMenu.includes(route)) {
        return null
      }
    }

    if (role === "ADMIN" && !adminMenu.includes(route)) {
      return null
    }

    return {
      key,
      icon,
      route,
      children,
      label,
    } as MenuItem
  }

  const items: MenuItem[] = [
    getItem(
      "Dashboard",
      "1",
      "dashboard",
      <NavLink to={"dashboard"}>
        <Home />
      </NavLink>
    ),
    getItem(
      "Jobs",
      "2",
      "jobs",
      <NavLink to={"jobs"}>
        <FileList />
      </NavLink>
    ),
    getItem(
      "Quotes",
      "3",
      "quotes",
      <NavLink to={"quotes"}>
        <Quotes />
      </NavLink>
    ),
    getItem(
      "Invoices",
      "4",
      "invoices",
      <NavLink to={"invoices"}>
        <Invoices />
      </NavLink>
    ),
    getItem(
      "Sales",
      "5",
      "sales",
      <NavLink to={"sales"}>
        <Sales />
      </NavLink>
    ),
    getItem(
      "Contacts",
      "6",
      "contacts",
      <NavLink to={"contacts"}>
        <Contacts />
      </NavLink>
    ),
    getItem(
      "Custom forms",
      "7",
      "forms",
      <NavLink to={"forms"}>
        <Notepad />
      </NavLink>
    ),
  ]?.filter(Boolean) as MenuItem[]

  const settingsItem: MenuItem[] = [
    getItem(
      "Settings",
      "8",
      "profile",
      <NavLink to={"profile"}>
        <Settings />
      </NavLink>
    ),
    getItem(
      <Paragraph className={"log-out-text"} onClick={handleConfirmSignOut} style={{ color: "" }}>
        Log out
      </Paragraph>,
      "9",
      "logout",
      <LogoutOutlined style={{ fontSize: 22 }} onClick={handleConfirmSignOut} />
    ),
  ]?.filter(Boolean) as MenuItem[]

  useEffect(() => {
    const pathname = location.pathname
    const foundItem = items.find(item => `/${item?.route}` === pathname)
    if (pathname === "/profile") setSelectedKey("8")

    if (foundItem && foundItem.key) setSelectedKey(foundItem.key.toString())
  }, [location, items, settingsItem])

  return (
    <Row className={"menu-wrapper"} align={"middle"} justify={isMobile ? "start" : "space-between"}>
      <Space
        direction={"vertical"}
        size={isMobile ? 40 : undefined}
        style={{ width: "100%", marginBottom: isMobile ? 20 : 0 }}
      >
        <Row className={"logo-wrapper"} justify={"center"}>
          <Link to={"/"} style={{ display: "flex", alignItems: "center" }}>
            <LogoSvg />
          </Link>
        </Row>
        <Menu
          className={"main-menu"}
          theme={"dark"}
          onSelect={onSelect}
          defaultSelectedKeys={["1"]}
          mode={"inline"}
          items={items}
          selectedKeys={[selectedKey]}
        />

        <Menu theme={"dark"} onSelect={onSelect} mode={"inline"} items={settingsItem} selectedKeys={[selectedKey]} />
      </Space>
    </Row>
  )
}

export { SideBarMenu }
