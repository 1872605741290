import { FC, lazy, useEffect, useState } from "react"
import { RouterProvider } from "react-router"
import { Navigate, RouteObject, createBrowserRouter } from "react-router-dom"
import { useApp } from "src/app"
import { AuthLayout } from "src/components/layout/AuthLayout"
import { DefaultLayout } from "src/components/layout/DefaultLayout"
import { RegisteredLayout } from "src/components/layout/RegisteredLayout"
import { Loader } from "src/components/ui/Loader"

const Home = lazy<FC>(() => import("./home"))
const Pricing = lazy<FC>(() => import("./pricing"))
const Forms = lazy<FC>(() => import("./forms"))
const Form = lazy<FC>(() => import("./forms/form"))
const Quotes = lazy<FC>(() => import("./quotes"))
const QuoteDetails = lazy<FC>(() => import("./quotes/quote-details"))
const Jobs = lazy<FC>(() => import("./jobs"))
const JobSingle = lazy<FC>(() => import("./jobs/job"))
const Sales = lazy<FC>(() => import("./sales"))
const Contacts = lazy<FC>(() => import("./contacts"))
const Dashboard = lazy<FC>(() => import("./dashboard"))
const Notifications = lazy<FC>(() => import("./notifications"))
const SignUp = lazy<FC>(() => import("./sign-up"))
const Search = lazy<FC>(() => import("./search"))
const SignIn = lazy<FC>(() => import("./sign-in"))
const PrivacyPolicy = lazy<FC>(() => import("./privacy-policy"))
const NotFound = lazy<FC>(() => import("./not-found"))
const Terms = lazy<FC>(() => import("./terms"))
const Profile = lazy<FC>(() => import("./profile"))
const Invoices = lazy<FC>(() => import("./invoices"))
const InvoiceDetails = lazy<FC>(() => import("./invoices/invoice-details"))
const ForgotPassword = lazy<FC>(() => import("./forgot-password"))
const SignUpEmployer = lazy<FC>(() => import("./sign-up-employer"))
const PasswordReset = lazy<FC>(() => import("./reset-password"))
const VerifyEmail = lazy<FC>(() => import("./verify-email"))
const PersonalInfo = lazy<FC>(() => import("./profile/pages/personal-info"))
const Notification = lazy<FC>(() => import("./profile/pages/notifications"))
const Members = lazy<FC>(() => import("./profile/pages/members"))
const Integration = lazy<FC>(() => import("./profile/pages/integration"))
const Password = lazy<FC>(() => import("./profile/pages/password"))
const Subscription = lazy<FC>(() => import("./profile/pages/subscription"))
const PaymentMethod = lazy<FC>(() => import("./profile/pages/payment-method"))
const ResponseForm = lazy<FC>(() => import("./response-form"))

const commonRoutes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Home,
        path: "",
        index: true,
      },
      {
        Component: Pricing,
        path: "/pricing",
        index: true,
      },
      {
        Component: PrivacyPolicy,
        path: "/privacy",
      },
      {
        Component: Terms,
        path: "/terms",
      },
    ],
  },
  {
    Component: NotFound,
    path: "*",
  },
]

const guestRoutes: RouteObject[] = [
  {
    Component: AuthLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        element: <Navigate to={"/sign-in"} replace />,
        path: "*",
      },
      {
        Component: SignUp,
        path: "/sign-up",
      },
      {
        Component: SignUpEmployer,
        path: "/sign-up-employer",
      },
      {
        Component: SignIn,
        path: "/sign-in",
      },
      {
        Component: PasswordReset,
        path: "/password-reset",
      },
      {
        Component: ForgotPassword,
        path: "/forgot-password",
      },
      {
        Component: VerifyEmail,
        path: "/verify-email",
      },
      {
        Component: SignUp,
        path: "/invitation/:token",
      },
    ],
  },
]

const authRoutes: RouteObject[] = [
  {
    Component: RegisteredLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Profile,
        path: "profile",
        children: [
          {
            element: <Navigate to={"personal-info"} replace />,
            path: "",
          },
          import.meta.env.VITE_IS_PROD
            ? {
                element: <Navigate to={"/"} replace />,
                path: "subscription",
              }
            : {
                Component: Subscription,
                path: "subscription",
              },
          import.meta.env.VITE_IS_PROD
            ? {
                element: <Navigate to={"/"} replace />,
                path: "payment-method",
              }
            : {
                Component: PaymentMethod,
                path: "payment-method",
              },
          {
            Component: PersonalInfo,
            path: "personal-info",
          },
          {
            Component: Notification,
            path: "notifications",
          },
          {
            Component: Integration,
            path: "integration",
          },
          {
            Component: Password,
            path: "password",
          },
        ],
      },
    ],
  },
]

const adminRoutes: RouteObject[] = [
  {
    Component: RegisteredLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Sales,
        path: "/sales",
      },
      {
        Component: Jobs,
        path: "/jobs",
      },
      {
        Component: JobSingle,
        path: "/jobs/:id",
      },
      {
        Component: Quotes,
        path: "/quotes",
      },
      {
        Component: QuoteDetails,
        path: "/quotes/:id",
      },
      {
        Component: Invoices,
        path: "/invoices",
      },
      {
        Component: InvoiceDetails,
        path: "/invoices/:id",
      },
      {
        Component: Forms,
        path: "/forms",
      },
      {
        Component: Form,
        path: "/forms/:id",
      },
      {
        Component: ResponseForm,
        path: "/response-form/:id",
      },
      {
        Component: Contacts,
        path: "/contacts",
      },
      {
        Component: Dashboard,
        path: "/dashboard",
      },
      {
        Component: Notifications,
        path: "/notifications",
      },
      {
        Component: Profile,
        path: "/profile",
        children: [
          {
            Component: Members,
            path: "members",
          },
        ],
      },
      {
        element: <Navigate to={"/profile"} replace />,
        path: "*",
      },
    ],
  },
  {
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Search,
        path: "/search",
      },
      {
        Component: Forms,
        path: "/forms",
      },
      {
        element: <Navigate to={"/profile"} replace />,
        path: "*",
      },
    ],
  },
]

const yardRoutes: RouteObject[] = [
  {
    Component: RegisteredLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Jobs,
        path: "/jobs",
      },
      {
        Component: Contacts,
        path: "/contacts",
      },
      {
        Component: Dashboard,
        path: "/dashboard",
      },
      {
        element: <Navigate to={"/profile"} replace />,
        path: "*",
      },
    ],
  },
]

const onsiteRoutes: RouteObject[] = [
  {
    Component: RegisteredLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Dashboard,
        path: "/dashboard",
      },
      {
        element: <Navigate to={"/profile"} replace />,
        path: "*",
      },
    ],
  },
]

const Router: FC = () => {
  const { user } = useApp()
  const [routes, setRoutes] = useState<RouteObject[]>(commonRoutes)
  const role = user?.role as RoleType

  useEffect(() => {
    if (role === "ADMIN") {
      setRoutes([...adminRoutes, ...authRoutes, ...commonRoutes])
    } else if (role === "MANAGEMENT") {
      setRoutes([...adminRoutes, ...authRoutes, ...commonRoutes])
    } else if (role === "YARD") {
      setRoutes([...yardRoutes, ...authRoutes, ...commonRoutes])
    } else if (role === "ONSITE") {
      setRoutes([...onsiteRoutes, ...authRoutes, ...commonRoutes])
    } else {
      setRoutes([...guestRoutes, ...commonRoutes])
    }
  }, [role, user])

  const router = createBrowserRouter(routes)

  return <RouterProvider router={router} fallbackElement={null} future={{ v7_startTransition: true }} />
}

export default Router
