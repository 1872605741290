import React from "react"

export const highlightMatchWithSearch = (text: string, searchQuery: string) => {
  const index = text?.toLowerCase()?.indexOf(searchQuery?.toLowerCase());
  if (index !== -1) {
    const prefix = text?.substring(0, index);
    const match = text?.substring(index, index + searchQuery?.length);
    const suffix = text?.substring(index + searchQuery?.length);
    return (
      <React.Fragment>
        {prefix}
        <span style={{ color: '#0073DD' }}>{match}</span>
        {suffix}
      </React.Fragment>
    );
  }
  return text;
};
