import { Layout, Spin } from "antd"
import { FC, Suspense, useState } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { useBreakpoints } from "src/hooks/useBreakpoints"
import { Loader } from "../../ui/Loader"
import { DashboardHeader } from "../DashboardHeader"
import { SideBarMenu } from "../SideBarMenu"
import "./index.less"

const { Sider, Header } = Layout

const RegisteredLayout: FC = () => {
  const [collapsed, setCollapsed] = useState(false)
  const location = useLocation()
  const { isMobile, isTabletMD } = useBreakpoints()

  const isProfilePage = location.pathname === "/profile"
  const shouldShowHeader = isProfilePage ? isTabletMD : true

  return (
    <Layout hasSider={!isMobile} style={{ minHeight: "100vh" }}>
      {!isMobile && (
        <Sider
          collapsible
          className={"authenticated-slider"}
          collapsed={collapsed}
          onCollapse={value => setCollapsed(value)}
          width={205}
          style={{ padding: "40px 28px", overflow: "hidden" }}
        >
          <SideBarMenu />
        </Sider>
      )}
      <Layout.Content className={"authenticated-layout"}>
        <div className={"authenticated-layout-content"}>
          {shouldShowHeader && (
            <Header className={"custom-header"}>
              <DashboardHeader />
            </Header>
          )}
          <Suspense fallback={<Loader size={"large"} spinning />}>
            <Outlet />
          </Suspense>
        </div>
      </Layout.Content>
    </Layout>
  )
}

export { RegisteredLayout }
